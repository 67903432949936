import axios from 'axios';
import PhotoAlbum from 'react-photo-album';
import { useEffect, useRef, useState } from 'react';

const hidingWindowMs = 4000;
const baseUrl = process.env.REACT_APP_BACKEND_URL;
const rowCount = 3;

type IImage = {
	displayed: boolean;
	height: number;
	width: number;
	id: string;
	src: string;
};

export default function Gallery() {
    const [hiding, setHiding] = useState<boolean>(false);
	const [showing, setShowing] = useState<boolean>(false);
	const [windowHeight, setWindowHeight] = useState<number>(
		window.innerHeight
	);

	const lastImageId = useRef<string>('');
	const [images, setImages] = useState<IImage[]>([]);

	async function getImages() {
		try {
			setHiding(true);
			await sleep(hidingWindowMs);
			setHiding(false);

			let data: {
				count: number;
				afterImageId?: string;
			} = {
				count: 10,
			};
			if (lastImageId?.current) {
				data = {
					...data,
					afterImageId: lastImageId.current,
				};
			}

			type ImageRes = Omit<IImage, 'src'> & { url: string };
			const response = await axios.get<{
				images: ImageRes[];
				lastImageId: string;
			}>(
				`${baseUrl}/image`,
				// 'https://excel-magic-mirror-stag-backend-z5t623hcnq-as.a.run.app/image',
				{params: data}
			);


      setShowing(true);
			console.log('Got data');
			setImages(
				response.data.images.map((img) => {

					const originalWidth = img.width || 800;  // default width
					const originalHeight = img.height || 600; // default height
					const eachRowHeight = windowHeight / rowCount;
					const scale = eachRowHeight / 800;

					return {
						width: originalWidth * scale,
						height: originalHeight * scale,
						id: img.id,
						src: img.url,
						displayed: img.displayed,
					};
				})
			);
			lastImageId.current = response.data.lastImageId;
			await sleep(hidingWindowMs);
			setShowing(false);
		} catch (err) {
			console.log(err);
		} finally {
			setTimeout(() => {
				getImages();
			}, 5000);
		}
	}

	useEffect(() => {
		getImages();

    // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const handleResize = () => {
			setWindowHeight(window.innerHeight);
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	console.log({ hiding });

	let animationStr = '';
	if (hiding) {
		animationStr = `hide ${
			(Math.random() * hidingWindowMs) / 1000
		}s forwards 0s 1 normal`;
	} else if (showing) {
		// animationStr = `show ${
		// 	(Math.random() * hidingWindowMs) / 1000
		// }s forwards 0s 1 normal`;
	}

  let classNameImage = 'image';
  if(hiding) {
    classNameImage = 'image-hiding';
  } else if(showing) {
    classNameImage = 'image-showing';
  }

	return (
		<div className='wrapper'>
			<PhotoAlbum
				// targetRowHeight={windowHeight/3}
				layout='rows'
				photos={images}
				renderContainer={({
					containerRef,
					containerProps,
					children,
				}) => (
					<div
						ref={containerRef}
						{...containerProps}
						style={{
							width: '100%',
							height: '100%',
						}}
					>
						{children}
					</div>
				)}
				renderPhoto={({
					imageProps: { src, alt, style, ...restImageProps },
				}) => (
					<img
						src={src}
						alt={alt}
						style={{
							...style,
							borderRadius: '10px',
							animation: animationStr,
							animationPlayState:
								hiding || showing ? 'running' : 'paused',
							animationDuration:
								hiding || showing
									? (Math.random() * hidingWindowMs) / 1000 +
									  's'
									: '0s',
							objectFit: 'cover',
							width: '100%',
							height: '100%',
						}}
						className={classNameImage}
						{...restImageProps}
					/>
				)}
			/>
		</div>
	);
}

async function sleep(ms: number): Promise<void> {
	return new Promise((resolve) => {
		setTimeout(resolve, ms);
	});
}
